import React from 'react';
import './App.css';
import herenowLogo from './img/herenow.jpg';
import rowingMavensLogo from './img/rowingMavensLogo.svg';

function App() {
  return (
    <div className="App">
      <h1>Tigato Ventures</h1>

      <h2>Graduated</h2>
      <div className="graduatedCompanies">
        <div className="company">
          <div className="logoContainer"><a href="http://herenow.com" target="_blank" rel="noopener noreferrer"><img src={herenowLogo} className="companyLogo" alt="herenow" /></a></div>
          <div className="description">Realtime data and streaming video services</div>
        </div>
        <div className="company">
          <div className="logoContainer"><a href="http://rowingmavens.com" target="_blank" rel="noopener noreferrer"><img src={rowingMavensLogo} className="companyLogo" alt="rowing mavens" /></a></div>
          <div className="description">Organization of timers, referees, regatta directors, and coaches providing services for the sport of rowing</div>
        </div>
      </div>

      <h2>Incubating</h2>
      <ul>
        <li><a href="http://fullgallop.com" target="_blank" rel="noopener noreferrer">Full Gallop Video Production</a></li>
        <li><span>Strongwing Drones</span></li>
        <li><span>Medway Innovation Labs</span></li>
        <li><a href="http://energeticglobal.com" target="_blank" rel="noopener noreferrer">Energetic Global</a></li>
        <li><span>Oomgood Performance Testing</span></li>
      </ul>
    </div>
  );
}

export default App;
